import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

import style from './LegalInfo.module.css';

const LegalInfo = ({ type }) => {
  return (
    <div className={style.container}>
      <h1 className={style.title}>
        <FormattedMessage id={`main.legalInfo.${type}.title`} />
      </h1>
      <div>
        <FormattedHTMLMessage id={`main.legalInfo.${type}.text`} />
      </div>
    </div>
  );
};

LegalInfo.propTypes = {
  type: PropTypes.oneOf(['privacy', 'terms']),
};

export default LegalInfo;
