import React from 'react';

import Layout from '../components/Layout';
import LegalInfo from '../components/LegalInfo';

const PrivacyPolicy = () => (
  <Layout>
    <LegalInfo type="privacy" />
  </Layout>
);

export default PrivacyPolicy;
